import ModalABCValues from 'components/Modal/ABCValues';
import ModalBaseAdd from 'components/Modal/BaseAdd';
import ModalBaseSelect from 'components/Modal/BaseSelect';
import ModalBudgetComponentObservation from 'components/Modal/BudgetComponentObservation';
import ModalBudgetCompositionEdit from 'components/Modal/BudgetCompositionEdit';
import ModalBudgetCompositionFragmentSelect from 'components/Modal/BudgetCompositionFragmentSelect';
import ModalBudgetCompositionLookup from 'components/Modal/BudgetCompositionLookup';
import ModalBudgetCompositionSelect from 'components/Modal/BudgetCompositionSelect';
import ModalBudgetCustomCompositionAdd from 'components/Modal/BudgetCustomCompositionAdd';
import ModalBudgetHistory from 'components/Modal/BudgetHistory';
import ModalBudgetInputFragmentSelect from 'components/Modal/BudgetInputFragmentSelect';
import ModalBudgetInputSelect from 'components/Modal/BudgetInputSelect';
import ModalBudgetOutdatedComponentSelect from 'components/Modal/BudgetOutdatedComponentSelect';
import ModalClientLookup from 'components/Modal/ClientLookup';
import ModalClientSelfLookup from 'components/Modal/ClientSelfLookup';
import ModalCompositionLookup from 'components/Modal/CompositionLookup';
import ModalCompositionPricesLookup from 'components/Modal/CompositionPriceLookup';
import ModalGPTModel from 'components/Modal/GPTModel';
import ModalImport from 'components/Modal/Import';
import ModalImportCompositionFields from 'components/Modal/Import/Composition/Fields';
import ModalImportCompositionOverview from 'components/Modal/Import/Composition/Overview';
import ModalImportInputFields from 'components/Modal/Import/Input/Fields';
import ModalImportInputOverview from 'components/Modal/Import/Input/Overview';
import ModalInputPricesLookup from 'components/Modal/InputPriceLookup';
import ModalLocaleAndPriceTypeSelect from 'components/Modal/LocaleAndPriceTypeSelect';
import ModalManageFlow from 'components/Modal/ManageFlow';
import ModalManageParametricFlow from 'components/Modal/ManageParametricFlow';
import ModalOwnCompositionSelect from 'components/Modal/OwnCompositionSelect';
import ModalOwnInputSelect from 'components/Modal/OwnInputSelect';
import ModalParametricBudgetHistory from 'components/Modal/ParametricBudgetHistory';
import ModalParametricBudgetLookup from 'components/Modal/ParametricBudgetLookup';
import ModalPositionUpdate from 'components/Modal/PositionUpdate';
import ModalStageAdd from 'components/Modal/StageAdd';
import ModalSubscriptionHistory from 'components/Modal/SubscriptionHistory';
import ModalSupport from 'components/Modal/Support';
import ModalUserInvite from 'components/Modal/UserInvite';
import ModalUserOrganizationLookup from 'components/Modal/UserOrganizationLookup';
import ModalVersionAdd from 'components/Modal/VersionAdd';

export default {
  baseAdd: ModalBaseAdd,
  baseSelect: ModalBaseSelect,
  versionAdd: ModalVersionAdd,
  clientLookup: ModalClientLookup,
  clientSelfLookup: ModalClientSelfLookup,
  compositionLookup: ModalCompositionLookup,
  budgetCompositionLookup: ModalBudgetCompositionLookup,
  compositionPriceLookup: ModalCompositionPricesLookup,
  inputPriceLookup: ModalInputPricesLookup,
  userInvite: ModalUserInvite,
  budgetCompositionSelect: ModalBudgetCompositionSelect,
  budgetCompositionFragmentSelect: ModalBudgetCompositionFragmentSelect,
  budgetComponentObservation: ModalBudgetComponentObservation,
  budgetInputSelect: ModalBudgetInputSelect,
  budgetInputFragmentSelect: ModalBudgetInputFragmentSelect,
  stageAdd: ModalStageAdd,
  positionUpdate: ModalPositionUpdate,
  ownCompositionSelect: ModalOwnCompositionSelect,
  ownInputSelect: ModalOwnInputSelect,
  import: ModalImport,
  inputImportFields: ModalImportInputFields,
  compositionImportFields: ModalImportCompositionFields,
  importInputOverview: ModalImportInputOverview,
  importCompositionOverview: ModalImportCompositionOverview,
  budgetCompositionEdit: ModalBudgetCompositionEdit,
  subscriptionHistory: ModalSubscriptionHistory,
  userOrganizationLookup: ModalUserOrganizationLookup,
  abcValues: ModalABCValues,
  support: ModalSupport,
  flowChange: ModalManageFlow,
  budgetHistory: ModalBudgetHistory,
  parametricFlowChange: ModalManageParametricFlow,
  parametricBudgetHistory: ModalParametricBudgetHistory,
  gptModel: ModalGPTModel,
  parametricBudgetLookup: ModalParametricBudgetLookup,
  budgetCustomCompositionAdd: ModalBudgetCustomCompositionAdd,
  budgetOutdatedComponentSelect: ModalBudgetOutdatedComponentSelect,
  localeAndPriceTypeSelect: ModalLocaleAndPriceTypeSelect,
};
